<template>
  <div class="dashboard p-5">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Nazorg - Zorg en ondersteuning</h1>
        <div class="row">
          <p class="intro-section-text">
            Onderstaand dashboard geeft informatie weer over de <b>Zorg en ondersteuning</b> als onderdeel van de levenspadfase <b>Nazorg</b>.
            In dit dashboard wordt informatie weergegeven over mensen met dementie die overleden zijn, over de periode tot 3 jaar voor hun overlijden en het eventuele zorggebruik in deze periode. Deze informatie is enkel beschikbaar op zorgkantoorregio-niveau.
          </p>
        </div>
      </div>
    </div>

   <div class="row" v-if="selectedLevel==='zorgkantoor'">
     <div class="col-sm-12 down">
       <card :cardId="'Percentage met diagnose in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij in de 3 jaar vóór overlijden ook de diagnose dementie hadden.'" 
          :x="zorggebruikddt_t" :y="zorggebruikddt_dementie.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 4300 : 3000">
         <template slot="card-header">Percentage met diagnose in jaren voor overlijden</template>
         <div slot="card-body">
           Het percentage van mensen dat diagnose dementie heeft, van drie jaar vóór overlijden tot het overlijdensjaar. In het jaar van overlijden heeft iedereen de diagnose dementie.
           <line-chart-for v-if="load.graphOne"
           :tickvals="[-4,-3,-2,-1,0]"
           :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
           :xtitle="''"
           :ytitle="'% met diagnose dementie'"
           :xdata="t_dementie_landelijk"
           :ydata="dementie_landelijk"
           :zorgkantoor="zorgkantoor"/>
         </div>
       </card>
     </div>
   </div>

   <div class="row" v-if="selectedLevel==='dementienetwerk'">
     <div class="col-sm-12 down">
       <card-small :cardId="'nazorg-ondersteuning-1-1'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
         <template slot="card-header">Percentage met diagnose in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
       </card-small>
     </div>
   </div>

   <div class="row" v-if="selectedLevel==='zorgkantoor'" >
     <div class="col-sm-12 down">
       <card :cardId="'Percentage in verpleeghuis in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij op in het jaar van overlijden en in de 3 jaar vóór overlijden in een verpleeghuis woonden. Let op: mensen kunnen ook in een verpleeghuis wonen o.b.v. een andere indicatie dan dementie.'" 
          :x="zorggebruikddt_t" :y="zorggebruikddt_verpleeghuis.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 4300 : 3000">
         <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden</template>
         <div slot="card-body">
           Het percentage mensen dat in een verpleeghuis woont, van drie jaar vóór overlijden tot het overlijdensjaar.
           <line-chart-for v-if="load.graphOne"
           :tickvals="[-4,-3,-2,-1,0]"
           :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
           :xtitle="''"
           :ytitle="'% in verpleeghuis'"
           :xdata="t_dementie_landelijk"
           :ydata="verpleeghuis_landelijk"
           :zorgkantoor="zorgkantoor"/>
         </div>
       </card>
     </div>
   </div>

   <div class="row" v-if="selectedLevel==='dementienetwerk'">
     <div class="col-sm-12 down">
       <card-small :cardId="'Percentage in verpleeghuis in jaren voor overlijden'"  :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
         <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
       </card-small>
     </div>
   </div>

   <div class="row" v-if="selectedLevel==='zorgkantoor'">
     <div class="col-sm-12 down">
       <card :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden hulpmiddelen of diensten vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
          :x="zorggebruikddt_t" :y="zorggebruikddt_hulpmiddelendiensten.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="selectedLevel === 'dementienetwerk' ? 4300 : 3000">
         <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden</template>
         <div slot="card-body">
           Het percentage mensen dat hulpmiddelen of diensten ontvangen vanuit Wmo, van drie jaar vóór overlijden tot het overlijdensjaar.
           <line-chart-for v-if="load.graphOne"
           :tickvals="[-4,-3,-2,-1,0]"
           :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
           :xtitle="''"
           :ytitle="'% met hulpmiddelen of diensten'"
           :xdata="t_dementie_landelijk"
           :ydata="hulpmiddelendiensten_landelijk"
           :zorgkantoor="zorgkantoor"/>
         </div>
       </card>
     </div>
   </div>

   <div class="row" v-if="selectedLevel==='dementienetwerk'">
     <div class="col-sm-12 down">
       <card-small :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden'" :hovertext="'Deze informatie is vanwege het risico op onthulling van individuele zorginstellingen (voorlopig) alleen op zorgkantoorregio-niveau beschikbaar. De data over de mensen met dementie komt uit de cbs microdata.'">
         <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden - uitsluitend op zorgkantoorregio-niveau</template>
       </card-small>
     </div>
   </div>

   <div class="row" v-if="selectedLevel !== 'landelijk'">
     <div class="col-sm-12 down">
       <card :cardId="'Hoeveel mensen met dementie bezoeken het ziekenhuis'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden zijn opgenomen in een ziekenhuis. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
          :x="t" :y="percentage_opname_ziekenhuis_table.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="400">
         <template slot="card-header">Hoeveel mensen met dementie bezoeken het ziekenhuis?</template>
         <div slot="card-body">
           Het percentage van mensen met dementie dat is opgenomen in het ziekenhuis, van drie jaar vóór overlijden tot het overlijdensjaar. Deze informatie is alleen op landelijk niveau beschikbaar.
           <line-chart-t v-if="load.graphTwo"
           :tickvals="[-4,-3,-2,-1,0]"
           :ticktext="['4 jaar voor overlijden', '3 jaar voor overlijden', '2 jaar voor overlijden', '1 jaar voor overlijden', 'jaar van overlijden']"
           :xtitle="''"
           :ytitle="'% opgenomen in ziekenhuis'"
           :bottom="40"
           :data1="[{ mode: 'line', name:'Landelijk percentage',
           x: t , y: percentage_opname_ziekenhuis, marker: {
           color: 'rgba(180,180,180,1.00)'}}]"/>
         </div>
       </card>
     </div>
   </div>

   <!-- Landelijk Plots -->
   <div class="row" v-if="selectedLevel === 'landelijk'">
    <div class="col-sm-12 down">
      <card :cardId="'Percentage met diagnose in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij in de 3 jaar vóór overlijden ook de diagnose dementie hadden.'" 
        :x="zorggebruikddt_t" :y="zorggebruikddt_dementie_table.map(value => `${value}%`)"
        :xtitle="'Jaar'" :ytitle="'Percentage'"
        :tableHeight="400">
        <template slot="card-header">Percentage met diagnose in jaren voor overlijden</template>
        <div slot="card-body">
          Onderstaande grafiek toont het landelijke niveau van het percentage mensen met de diagnose dementie, van drie jaar vóór het overlijden tot het jaar van overlijden. Bij iedereen wordt dementie vastgesteld in het jaar van overlijden.
          <stacked-bar-chart-colors v-if="load.graphThree" :tickangle="0"
            :ytitle="'% met diagnose dementie'"
            :x="[zorggebruikddt_t]"
            :y="[zorggebruikddt_dementie]"
            :showlegend="false"
            :ticksuffix="'%'"/>
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Percentage in verpleeghuis in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij op in het jaar van overlijden en in de 3 jaar vóór overlijden in een verpleeghuis woonden. Let op: mensen kunnen ook in een verpleeghuis wonen o.b.v. een andere indicatie dan dementie.'" 
          :x="zorggebruikddt_t" :y="zorggebruikddt_verpleeghuis_table.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="400">
          <template slot="card-header">Percentage in verpleeghuis in jaren voor overlijden</template>
          <div slot="card-body">
            Onderstaande grafiek toont het landelijke niveau van het percentage mensen dat in een verpleeghuis woont, van drie jaar vóór het overlijden tot het jaar van overlijden.
            <stacked-bar-chart-colors v-if="load.graphThree"
              :ytitle="'% in verpleeghuis'" :tickangle="0"
              :x="[zorggebruikddt_t]"
              :y="[zorggebruikddt_verpleeghuis]"
              :showlegend="false"
              :ticksuffix="'%'"/>
            </div>
          </card>
        </div>
      </div>

    <div class="row" v-if="selectedLevel === 'landelijk'">
      <div class="col-sm-12 down">
        <card :cardId="'Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden'" :hovertext="'Populatie: iedereen die op moment van overlijden de diagnose dementie heeft, 2015-2019. Van al deze personen is gekeken of zij in het jaar van overlijden en in de 3 jaar vóór overlijden hulpmiddelen of diensten vanuit Wmo hebben ontvangen. Let op: mensen kunnen ook hulpmiddelen of diensten ontvangen o.b.v. een andere indicatie dan dementie.'" 
          :x="zorggebruikddt_t" :y="zorggebruikddt_hulpmiddelendiensten_table.map(value => `${value}%`)"
          :xtitle="'Jaar'" :ytitle="'Percentage'"
          :tableHeight="400">
          <template slot="card-header">Percentage met hulpmiddelen of diensten uit Wmo in jaren voor overlijden</template>
          <div slot="card-body">
            In onderstaande grafiek is het landelijke niveau weergegeven van het percentage mensen dat hulpmiddelen of diensten uit de Wmo ontvangt, van drie jaar vóór het overlijden tot het jaar van overlijden.
            <stacked-bar-chart-colors v-if="load.graphThree" :tickangle="0"
              :ytitle="'% met hulpmiddelen of diensten'"
              :x="[zorggebruikddt_t]"
              :y="[zorggebruikddt_hulpmiddelendiensten]"
              :showlegend="false"
              :ticksuffix="'%'"/>
            </div>
          </card>
        </div>
      </div>

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>


import Card from '../components/Card'
import LineChartFor from '../components/LineChartFor'
import StackedBarChartColors from '../components/StackedBarChartColors'
import LineChartT from '../components/LineChartT-4'
import { mapGetters } from 'vuex'
import CardSmall from '../components/CardSmall'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Nazorg - Zorg en ondersteuning',
  },
  components: { CardSmall, Card, LineChartFor, LineChartT, StackedBarChartColors, Footer},
  data () {
    return {
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false
      },
      zorggebruikddt_dementie: [],
      zorggebruikddt_verpleeghuis: [], 
      zorggebruikddt_hulpmiddelendiensten: [],
      zorggebruikddt_dementie_table: [],
      zorggebruikddt_verpleeghuis_table: [], 
      zorggebruikddt_hulpmiddelendiensten_table: [],
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      t_dementie_landelijk: [],
      dementie_landelijk: [],
      verpleeghuis_landelijk: [],
      hulpmiddelendiensten_landelijk: [],
      zorgkantoorregiocodes: [],
      zorgkantoor: [],
      zorggebruikddt_t:[],
      percentage_opname_ziekenhuis_table:[],
      t: [],
      percentage_opname_ziekenhuis: []
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    }
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_us_zorggebruikddt/get_data`).then(
        (response) => {
          response.data.sort((a,b) => a.zorgkantoorregiocode-b.zorgkantoorregiocode)
          this.zorgkantoorregiocodes = [...new Set(response.data.map(d => d.zorgkantoorregiocode))]
          for (var i = 0; i<this.zorgkantoorregiocodes.length; i++) {
            this.dementie_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_dementie))
            this.verpleeghuis_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_verpleeghuiszorg))
            this.hulpmiddelendiensten_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.percentage_hulpmiddelenDiensten))
            this.t_dementie_landelijk[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.t))
            this.zorgkantoor[i] = response.data.filter(x => x.zorgkantoorregiocode==this.zorgkantoorregiocodes[i]).map(d => (d.zorgkantoorregionaam))[0]
            }

            // a mapping object
            const tMapping = {
              '-3': '3 jaar voor overlijden',
              '-2': '2 jaar voor overlijden',
              '-1': '1 jaar voor overlijden',
              '0': 'jaar van overlijden'
            };

            // mapping the values
            this.zorggebruikddt_t = response.data.map(d => tMapping[d.t]);
            // this.zorggebruikddt_t = response.data.map(d => d.t)
            this.zorggebruikddt_dementie = response.data.map(d => (d.percentage_dementie*100).toFixed(1))
            this.zorggebruikddt_verpleeghuis = response.data.map(d => (d.percentage_verpleeghuiszorg*100).toFixed(1))
            this.zorggebruikddt_hulpmiddelendiensten = response.data.map(d => (d.percentage_hulpmiddelenDiensten*100).toFixed(1))
          this.load.graphOne=true
        }
      )
      }
      else if(level === 'landelijk'){
        this.$http.get(`/api/zorgkantoor_us_zorggebruikddt/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.t - b.t)
            this.zorggebruikddt_t = response.data.map(d => d.t)
            this.zorggebruikddt_dementie = response.data.map(d => (d.percentage_dementie*100))
            this.zorggebruikddt_verpleeghuis = response.data.map(d => (d.percentage_verpleeghuiszorg*100))
            this.zorggebruikddt_hulpmiddelendiensten = response.data.map(d => (d.percentage_hulpmiddelenDiensten*100))

             this.zorggebruikddt_dementie_table = response.data.map(d => (d.percentage_dementie*100).toFixed(1))
            this.zorggebruikddt_verpleeghuis_table = response.data.map(d => (d.percentage_verpleeghuiszorg*100).toFixed(1))
            this.zorggebruikddt_hulpmiddelendiensten_table = response.data.map(d => (d.percentage_hulpmiddelenDiensten*100).toFixed(1))
            this.load.graphThree=true
          }
        )
      }
    },
    loadNationalData () {
      this.$http.get(`/api/zorggebruik_nationaal/get_data`).then(
        (response) => {
          response.data.sort((a,b) => a.zorgkantoorregiocode-b.zorgkantoorregiocode)

          const tMapping = {
              '-3': '3 jaar voor overlijden',
              '-2': '2 jaar voor overlijden',
              '-1': '1 jaar voor overlijden',
              '0': 'jaar van overlijden'
            };

          this.t = response.data.map(d => tMapping[d.t]);
          // this.t = response.data.map(d => (d.t))
          this.percentage_opname_ziekenhuis_table = response.data.map(d => (d.percentage_opname_ziekenhuis*100).toFixed(1))
          this.percentage_opname_ziekenhuis = response.data.map(d => (d.percentage_opname_ziekenhuis))
          this.load.graphTwo=true
        }
      )
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel),
    this.loadNationalData()
  }
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>