<template>
    <vue-plotly :data="data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'LineChart',
  components: { VuePlotly },
  props: {
    data1: Array,
    bottom: {
      type: Number,
      default: 100
    },
    xtitle: {
      type: String,
      default: ''
    },
    ytitle: {
      type: String,
      default: ''
    },
    tickvals: {
      type: Array,
      default: null,
    },
    tickfont_size:{
      type: Number,
      default:10
    },
    ticktext: {
      type: Array,
      default: null,
    },
  },
  data () {
    return {
      data: this.data1,
      layout: {
        margin: {
      l: 50,
      r: 50,
      b: this.bottom,
      t: 30,
      pad: 4},
      height: 400,
      showlegend: true,
      yaxis: {
        automargin:true,
        range: [0, 1],
        tickformat: ',.0%',
        title: {
          standoff:20,
          text: this.ytitle,
          font: {
            family: 'Gotham',
            size: 14
          }
        },
      },
      xaxis: {
        automargin:true,
        tickvals: this.tickvals,
        ticktext: this.ticktext,
        tickfont:{
            size:this.tickfont_size
          },
        title: {
          standoff:20,
          text: this.xtitle,
          font: {
            family: 'Gotham',
            size: 14
          }
        },
        // tickmode: "linear", //  If "linear", the placement of the ticks is determined by a starting position `tick0` and a tick step `dtick`
        // tick0: -4,
        // dtick: 1,

      }
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}

</script>
